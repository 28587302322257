import { __assign } from "tslib";
import { MIGRATION_STATUS_NOT_STARTED } from '../../constants/migration-status';
import { CONTENT_MIGRATION_FETCH_STATUS_SUCCESS, CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS, CONTENT_MIGRATION_ROLLBACK_SUCCESS, } from './content-migration-actions';
export var INITIAL_STATE = {
    status: MIGRATION_STATUS_NOT_STARTED,
};
export default function contentMigrationStatusReducer(state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case CONTENT_MIGRATION_ROLLBACK_SUCCESS:
        case CONTENT_MIGRATION_FETCH_STATUS_SUCCESS:
        case CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS:
            return __assign(__assign({}, state), action.payload);
        default:
            return state;
    }
}
