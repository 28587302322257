import { MIGRATION_STATUS_REDIRECTS_SET_FAILED } from '../../constants/migration-status';
import createAction from '../../services/create-action';
import { isOwner } from '../current-user/current-user-selectors';
import { getViewMode } from '../search-params/search-params-selectors';
export var CONTENT_MIGRATION_FETCH_STATUS_REQUEST = 'contentMigration/FETCH_STATUS_REQUEST';
export var CONTENT_MIGRATION_FETCH_STATUS_SUCCESS = 'contentMigration/FETCH_STATUS_SUCCESS';
export var CONTENT_MIGRATION_FETCH_STATUS_FAILURE = 'contentMigration/FETCH_STATUS_FAILURE';
export var CONTENT_MIGRATION_SET_STATUS = 'contentMigration/SET_STATUS';
export var contentMigrationFetchStatusRequest = createAction(CONTENT_MIGRATION_FETCH_STATUS_REQUEST);
export var contentMigrationFetchStatusSuccess = createAction(CONTENT_MIGRATION_FETCH_STATUS_SUCCESS);
export var contentMigrationFetchStatusFailure = createAction(CONTENT_MIGRATION_FETCH_STATUS_FAILURE);
export var contentMigrationSetStatus = createAction(CONTENT_MIGRATION_SET_STATUS);
export var contentMigrationFetchStatus = function (viewMode) { return function (dispatch, getState, _a) {
    var request = _a.request;
    dispatch(contentMigrationFetchStatusRequest());
    var promise = request("/_api/content-migration/details?viewMode=".concat(viewMode || getViewMode(getState())));
    return promise
        .then(function (details) { return dispatch(contentMigrationFetchStatusSuccess(details)); }, function () { return dispatch(contentMigrationFetchStatusFailure()); })
        .then(function () { return promise; });
}; };
export var fetchContentMigrationStatusForBusinessManager = function () { return function (dispatch, getState) {
    var state = getState();
    return isOwner(state)
        ? dispatch(contentMigrationFetchStatus('editor'))
        : Promise.resolve();
}; };
export var CONTENT_MIGRATION_ROLLBACK_REQUEST = 'contentMigration/ROLLBACK_REQUEST';
export var CONTENT_MIGRATION_ROLLBACK_SUCCESS = 'contentMigration/ROLLBACK_SUCCESS';
export var CONTENT_MIGRATION_ROLLBACK_FAILURE = 'contentMigration/ROLLBACK_FAILURE';
export var contentMigrationRollbackRequest = createAction(CONTENT_MIGRATION_ROLLBACK_REQUEST);
export var contentMigrationRollbackSuccess = createAction(CONTENT_MIGRATION_ROLLBACK_SUCCESS);
export var contentMigrationRollbackFailure = createAction(CONTENT_MIGRATION_ROLLBACK_FAILURE);
export var contentMigrationRollback = function () { return function (dispatch, getState, _a) {
    var request = _a.request;
    dispatch(contentMigrationRollbackRequest());
    var viewMode = getViewMode(getState());
    var promise = request.post("/_api/content-migration/rollback?viewMode=".concat(viewMode));
    return promise
        .then(function (details) { return dispatch(contentMigrationRollbackSuccess(details)); }, function () { return dispatch(contentMigrationRollbackFailure()); })
        .then(function () { return promise; });
}; };
export var CONTENT_MIGRATION_SET_REDIRECTS_REQUEST = 'contentMigration/SET_REDIRECTS_REQUEST';
export var CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS = 'contentMigration/SET_REDIRECTS_SUCCESS';
export var CONTENT_MIGRATION_SET_REDIRECTS_FAILURE = 'contentMigration/SET_REDIRECTS_FAILURE';
export var contentMigrationSetRedirectsRequest = createAction(CONTENT_MIGRATION_SET_REDIRECTS_REQUEST);
export var contentMigrationSetRedirectsSuccess = createAction(CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS);
export var contentMigrationSetRedirectsFailure = createAction(CONTENT_MIGRATION_SET_REDIRECTS_FAILURE);
export var contentMigrationSetRedirects = function () { return function (dispatch, getState, _a) {
    var request = _a.request;
    dispatch(contentMigrationSetRedirectsRequest());
    var viewMode = getViewMode(getState());
    var promise = request.post("/_api/content-migration/set-redirects?viewMode=".concat(viewMode));
    return promise
        .then(function (details) { return dispatch(contentMigrationSetRedirectsSuccess(details)); }, function () {
        return dispatch(contentMigrationSetRedirectsFailure(MIGRATION_STATUS_REDIRECTS_SET_FAILED));
    })
        .then(function () { return promise; });
}; };
