import { FETCH_BLOG_DATA_SUCCESS, SET_BLOG_DATA, UPDATE_BLOG_DATA_SUCCESS, } from './app-data-actions';
export default function appData(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case FETCH_BLOG_DATA_SUCCESS:
        case UPDATE_BLOG_DATA_SUCCESS:
        case SET_BLOG_DATA:
            return action.payload;
        default:
            return state;
    }
}
