import { get } from 'lodash';
import { WixCodeApi } from '../types';

const isProduction = process.env.NODE_ENV === 'production';

const getIsDebug = (wixCodeApi: WixCodeApi) => {
  const { debug, ssrDebug } = get(
    wixCodeApi,
    'location.query',
    {} as Record<string, string>,
  );
  return (debug && debug !== 'false') || ssrDebug;
};

export default function getEnvironment(wixCodeApi: WixCodeApi) {
  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';

  return {
    isEditor,
    isPreview,
    isEditorSegment: isEditor || isPreview,
    isSite: wixCodeApi.window.viewMode === 'Site',
    isSSR: wixCodeApi.window.rendering.env === 'backend',
    isDebug: getIsDebug(wixCodeApi),
    isProduction,
  };
}
