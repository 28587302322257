import { omit } from 'lodash';
import { createAction } from '../../services';
export var SET_BLOG_DATA = 'appData/SET';
export var setAppData = createAction(SET_BLOG_DATA);
export var FETCH_BLOG_DATA_REQUEST = 'appData/FETCH_REQUEST';
export var FETCH_BLOG_DATA_SUCCESS = 'appData/FETCH_SUCCESS';
export var FETCH_BLOG_DATA_FAILURE = 'appData/FETCH_FAILURE';
export var fetchAppDataRequest = function () { return ({ type: FETCH_BLOG_DATA_REQUEST }); };
export var fetchAppDataSuccess = function (payload) { return ({
    type: FETCH_BLOG_DATA_SUCCESS,
    payload: payload,
}); };
export var fetchAppDataFailure = function () { return ({ type: FETCH_BLOG_DATA_FAILURE }); };
export function fetchAppData(promise) {
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(fetchAppDataRequest());
        promise =
            promise || request.metered
                ? request.metered('/_api/blogs/this')('/_api/blogs/this')
                : request('/_api/blogs/this');
        return promise
            .then(function (appData) { return dispatch(fetchAppDataSuccess(appData)); }, function () { return dispatch(fetchAppDataFailure()); })
            .then(function () { return promise; });
    };
}
export var UPDATE_BLOG_DATA_REQUEST = 'appData/UPDATE_REQUEST';
export var UPDATE_BLOG_DATA_SUCCESS = 'appData/UPDATE_SUCCESS';
export var UPDATE_BLOG_DATA_FAILURE = 'appData/UPDATE_FAILURE';
export var updateAppDataRequest = function () { return ({ type: UPDATE_BLOG_DATA_REQUEST }); };
export var updateAppDataSuccess = function (payload) { return ({
    type: UPDATE_BLOG_DATA_SUCCESS,
    payload: payload,
}); };
export var updateAppDataFailure = function () { return ({ type: UPDATE_BLOG_DATA_FAILURE }); };
export function updateAppData(data) {
    if (data === void 0) { data = {}; }
    return function (dispatch, getState, _a) {
        var request = _a.request;
        dispatch(updateAppDataRequest());
        var promise = request.patch('/_api/blogs/this', omit(data, ['_id', 'communityId']));
        return promise
            .then(function (post) { return dispatch(updateAppDataSuccess(post)); })
            .catch(function () { return dispatch(updateAppDataFailure()); })
            .then(function () { return promise; });
    };
}
